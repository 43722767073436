import Vue from 'vue';
import Cloudinarize from '@plugins/cloudinarize';
import KFormField from '@components/forms/form_field.vue';
import KInput from '@components/inputs/input.vue';
import SystemButton from '@components/buttons/system-button.vue';
import Box from '@components/box.vue';
import { initSentry } from '@utils';
import Loader from '@components/loader/loader.vue';
import Illustration from '@components/illustration.vue';
import AuthMixin from '../src/common/mixins/auth';

Vue.use(Cloudinarize);

document.addEventListener('DOMContentLoaded', () => {
  initSentry();

  // eslint-disable-next-line no-unused-vars
  const app = new Vue({
    el: '.login-vue',
    components: {
      KFormField,
      KInput,
      Box,
      Loader,
      SystemButton,
      Illustration,
    },

    mixins: [AuthMixin],

    mounted() {
      if (/[?&]login=/.test(window.location.search)) {
        $('#loginModal').modal('show');
      }
    },
  });
});
